import React, { useEffect, useState } from "react";
import { Image, Badge, Divider, Typography, List, message, Skeleton, Row, Col, Space, Select, Button, Flex } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { selectMenuFeed } from "../store/slice/MenuFeedAPISlice";
import * as Constant from "../Constants";
import {
  incrementQuantity,
  decrementQuantity
} from "../store/slice/MenuFeedAPISlice";
import {
  postInitializeQuotesApi,
  putQuotesUpdateItemsApi,
} from "../store/InitializeQuotesPostAPI";
import { useNavigate } from "react-router-dom";
import { fetchBrandId, fetchProduct } from "../utility/productUtils.js";
import TestResult from "./TestResult.js";
import DynamicProductCard from "./DynamicProductCard.js";
import LineageTag from "./LineageTag.js";
import { notNull } from "../sharedFunctions.js";
import WeightRange from "./WeightRange.js";
import { Swiper, SwiperSlide } from 'swiper/react';
import { isMedMenu } from "../utility/generalUtils.js";
import { getReferenceNo } from '../utility/orderUtils';
import { isDesktop, isMobile } from 'react-device-detect';

import { FreeMode, Pagination, Navigation, Virtual } from 'swiper/modules';
import { filterTime } from "../utility/filterUtils.js";
const { Title, Paragraph, Text } = Typography;

const ProductDetail = (props) => {
  const [activeProductCard, setActiveProductCard] = useState(null);
  const discounts = useSelector((state) => state?.menuFeed?.info?.product_data?.discounts);
  const venue = useSelector((state) => state?.venue?.info?.venue);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const settings = useSelector((state) => state?.content?.contentData?.menu);
  const primaryThc = settings?.primary_thc;
  const primaryCbd = settings?.primary_cbd;
  const [disabledButtons, setDisabledButtons] = useState({});
  const [waiting, setWaiting] = useState(false);
  const menuFeedData = useSelector(selectMenuFeed);
  const location = useLocation();
  let { brands, product_id, selectedVariant } = location.state || {};


  // Always re-read product from menu items.  The filtered product may not have all the variants.
  let product = null;
  if( product_id !== undefined ) {
    product = menuFeedData?.product_data?.menu_items?.find((item) => item.product_id === product_id )
  }
  if (brands === undefined || product_id === undefined) {
    const urlArr = location.pathname.split("/");
    const brandName = urlArr[1];
    const productNameWithSlug = urlArr[2];

    brands = fetchBrandId(brandName, menuFeedData);
    product = fetchProduct(productNameWithSlug, menuFeedData);
    product_id = product?.product_id;
    selectedVariant = product?.variants?.[0];
  }
  // const [images, setImages] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [shownProducts, setShownProducts] = useState(12);
  const [swiperRef, setSwiperRef] = useState(null);
  const categories = useSelector((state) => state?.menuFeed?.info?.product_data?.categories);
  const [displayImage, setDisplayImage] = useState(null);


  const [selectedWeight, setSelectedWeight] = useState("1.0 grams");
  const [relatedProducts, setRelatedProducts] = useState([]);

  const [variant, setVariant] = useState(
    selectedVariant
  );
  const [discount, setDiscount] = useState();
  const [med_discount, setMedDiscount] = useState();

  const productType = menuFeedData?.product_data?.product_types?.find((type) => type.id === product?.product_type_id);


  const changeVariant = (selected) => {
    const newVar = product?.variants?.find((va) => va?.id === selected)
    setVariant(newVar)
  }

  // useEffect(() => {
  //   const imagesList = product?.images ? [...product?.images] : [];
  //   imagesList.unshift(product?.product_image);

  //   const filteredImagesList = imagesList.filter((image, index) => {
  //     return imagesList.findIndex((img) => img.lg === image.lg) === index;
  //   });
  //   setImages(filteredImagesList);
  // }, [product]);

  useEffect(() => {
    setVariant(
      menuFeedData?.product_data?.menu_items
        ?.filter((item) => {
          return item.product_id === product_id;
        })[0]
        ?.variants?.filter((va) => va?.selected)[0]
    );
  }, [menuFeedData, product_id]);

  useEffect(() => {
    if(notNull(variant)){
      setDisplayImage(variant?.images?.length > 0 ? variant?.images[0]?.lg : product?.product_image?.lg)

      if( notNull(discounts)){
        setDiscount(discounts.find((d) => d.id === filterTime(discounts, variant.best_discount_ids)?.shift()?.id))
        setMedDiscount(discounts.find((d) => d.id === filterTime(discounts, variant.best_med_discount_ids)?.shift()?.id))
      }
    }
  }, [variant, discounts]);

  const selectedVenueSlug = useSelector(
    (store) =>
      store?.merchant?.info?.merchant?.venues.filter((vn) => vn.selected)[0]
        ?.slug
  );
  const defaultSlugId = selectedVenueSlug;

  // Related Products
  useEffect(() => {

    if (menuFeedData && selectedProduct) {
      const selectedProductId = selectedProduct[0]?.product_id;
      const selectedProductBrandId = selectedProduct[0]?.brand_id;

      // const filteredRelatedProducts =
      //   menuFeedData?.product_data?.menu_items?.filter(
      //     (item) => {
      //       if (selectedProduct[0]?.sdp_category !== null) {
      //         return( item.product_id !== selectedProductId &&
      //         item.sdp_category === selectedProduct[0]?.sdp_category)
      //       } else {
      //         return(item.product_id !== selectedProductId &&
      //         (item.brand_id === selectedProductBrandId ||
      //           item?.product_type_id === selectedProduct[0]?.product_type_id))
      //       }
      //     }
      //   ) || [];

      const filteredRelatedProducts =
        menuFeedData?.product_data?.menu_items?.filter((item) => item?.product_id !== selectedProductId && item?.product_type_id === selectedProduct[0]?.product_type_id
        ) || [];

        const sortedRelatedProducts = filteredRelatedProducts.sort((a, b) => {
          const aMatches = a.brand_id === selectedProductBrandId && a.sdp_category === selectedProduct[0]?.sdp_category;
          const bMatches = b.brand_id === selectedProductBrandId && b.sdp_category === selectedProduct[0]?.sdp_category;

          if (aMatches && !bMatches) return -1; // a comes first
          if (!aMatches && bMatches) return 1; // b comes first

          const aSdpMatch = a.sdp_category === selectedProduct[0]?.sdp_category;
          const bSdpMatch = b.sdp_category === selectedProduct[0]?.sdp_category;

          if (aSdpMatch && !bSdpMatch) return -1; // a comes first
          if (!aSdpMatch && bSdpMatch) return 1; // b comes first

          const aBrandMatch = a.brand_id === selectedProductBrandId;
          const bBrandMatch = b.brand_id === selectedProductBrandId;

          if (aBrandMatch && !bBrandMatch) return -1; // a comes first
          if (!aBrandMatch && bBrandMatch) return 1; // b comes first

          return 0; // maintain original order
        });

        setRelatedProducts(sortedRelatedProducts);


      setRelatedProducts(filteredRelatedProducts);
    }
  }, [menuFeedData, selectedProduct]);
  // End Related Products

  useEffect(() => {
    if (menuFeedData) {
      setSelectedProduct(
        menuFeedData?.product_data?.menu_items?.filter((item) => {
          return item.product_id === product_id;
        })
      );

      let defaultVarient = menuFeedData?.product_data?.menu_items?.filter(
        (item) => {
          return item.product_id === product_id;
        }
      )[0]?.variants[0];

      setSelectedWeight(`${defaultVarient?.unit} ${defaultVarient?.unit_type}`);
    }
  }, [menuFeedData, brands, product, product_id]);

  //SDP Data

  const sdpData = useSelector((store) => store?.sdpData?.info?.categories);
  const sdp_logo = useSelector((store) => store?.sdpData?.info?.sdp_logo);
  const iron_stem_logo = useSelector(
    (store) => store?.sdpData?.info?.iron_stem_logo
  );

  const menuFeedSdp = useSelector((store) => store?.menuFeed?.info?.product_data?.menu_items);

  const sdpDetailsData = useSelector((store) => {
    const categories = store?.sdpData?.info?.categories;

    return menuFeedSdp?.map((item) => {
      const sdpCategory = item?.sdp_category;

      const categoryMatch = categories?.find((categoryItem) => categoryItem.sdp_category === sdpCategory);

      if (categoryMatch) {
        const { name, description, color, nearest_sdp_category } = categoryMatch;
        const icon_url = categoryMatch?.icon_url;

        return { name, description, color, nearest_sdp_category, icon_url };
      }

      return null;
    });
  });

  //End SDP Data

  const venue_id = useSelector((store) => store?.venue?.info?.venue?.venue_id);
  const reference_no = getReferenceNo(venue_id);

  const items = useSelector(
    (store) => store?.quote?.info?.quote?.items
  );

  const divider = (description) => <Divider style orientation='left' orientationMargin={0}>{description}</Divider>

  const title = (title) => <Title level={4} style={{marginBottom: 0}}>{title}</Title>

  useEffect(() => {
    setWaiting(false)
    const head = document.head;
    const metaElements = [
      { itemProp: "name", content: variant?.name },
      { itemProp: "description", content: product?.description },
      { itemProp: "sku", content: variant?.name },
      { itemProp: "brand", content: product?.brand },
      { itemProp: "weight", content: variant?.unit + variant?.unit_type },
      { itemProp: "category", content: productType?.name },
      { itemProp: "image", content: displayImage },
      { itemProp: "priceCurrency", content: "USD" },
      { itemProp: "price", content: (variant?.price_cents / 100).toFixed(2) },
      { itemProp: "itemCondition", content: "https://schema.org/NewCondition" },
      { itemProp: "availability", content: "https://schema.org/InStock" },
      { itemProp: "offers", itemScope: true, itemType: "https://schema.org/Offer" }
    ];

    metaElements.forEach(metaData => {
      let existingMeta = document.querySelector(`meta[itemProp="${metaData.itemProp}"]`);
      if(existingMeta){
        existingMeta.setAttribute('content', metaData.content);
      }else{
        const meta = document.createElement('meta');
        Object.keys(metaData).forEach(key => {
          meta.setAttribute(key, metaData[key]);
        });
        head.appendChild(meta);
      }
    });
    document.title = `${product?.name} - ${variant?.unit}${variant?.unit_type} | ${productType?.name} | ${product?.brand} | ${venue?.name} | ${venue?.address}`;
  }, [items, variant, displayImage, product, productType, venue]);

  const addToCartItem = async (item) => {
    // Disable the button
    setDisabledButtons((prevDisabledButtons) => ({
      ...prevDisabledButtons,
      [item.id]: true,
    }));
    setWaiting(true)
    try {
      const slugId = sessionStorage.getItem("selectedVenue") || defaultSlugId;
      const existingCartItem = items?.find(
        (it) =>
          it.sku_id === item?.sku_id &&
          it.unit === variant?.unit &&
          it.unit_type === variant?.unit_type
      );

      if (
        existingCartItem &&
        existingCartItem.quantity === variant?.quantity_on_hand
      ) {
        message.warning("You have reached the maximum quantity");
        setWaiting(false)
      } else {
        if (reference_no) {
          const found = items.filter((it) => {
            if (
              it.sku_id === item?.sku_id &&
              it.unit === variant?.unit &&
              it.unit_type === variant?.unit_type
            ) {
              return it;
            }
          });
          if (found?.length > 0) {
            dispatch(
              putQuotesUpdateItemsApi({
                slug: slugId,
                reference_no,
                quote: {
                  items: [
                    ...items.map((it) => {
                      if (
                        it.sku_id === item?.sku_id &&
                        it.unit === variant?.unit &&
                        it.unit_type === variant?.unit_type
                      ) {
                        return {
                          sku_id: it?.sku_id,
                          unit: it?.unit,
                          unit_type: it?.unit_type,
                          quantity:
                            it?.quantity + variant?.quantity >
                              variant?.quantity_on_hand
                              ? variant?.quantity_on_hand
                              : it?.quantity + variant?.quantity,
                        };
                      } else {
                        return {
                          sku_id: it?.sku_id,
                          unit: it?.unit,
                          unit_type: it?.unit_type,
                          quantity: it?.quantity,
                        };
                      }
                    }),
                  ],
                },
              })
            );
            message.success({
              content: "Product quantity updated in cart",
              icon: <i className="fa fa-check-circle" style={{color: 'green'}}/>
            });
          } else {
            dispatch(
              putQuotesUpdateItemsApi({
                slug: slugId,
                reference_no,
                quote: {
                  items: [
                    {
                      sku_id: item?.sku_id,
                      unit: variant?.unit,
                      unit_type: variant?.unit_type,
                      quantity: variant?.quantity,
                    },
                    ...items.map((it) => {
                      return {
                        sku_id: it?.sku_id,
                        unit: it?.unit,
                        unit_type: it?.unit_type,
                        quantity: it?.quantity,
                      };
                    }),
                  ],
                },
              })
            );
          }
        } else {
          dispatch(
            postInitializeQuotesApi({
              slug: slugId,
              quote: {
                customer: {
                  customer_type: isMedMenu() ? "medical" : "recreational",
                },
                fulfillment:{
                  source: settings?.mode === "online" ? 'posabit_v2' : 'posabit_v2_instore',
                },
                items: [
                  {
                    sku_id: item?.sku_id,
                    unit: variant?.unit,
                    unit_type: variant?.unit_type,
                    quantity: variant?.quantity,
                  },
                ],
              },
            })
          );
          message.success({
            content: "Product Added to Cart",
            icon: <i className="fa fa-check-circle" style={{color: 'green'}}/>
          });
        }
      }
      setTimeout(() => {
        setDisabledButtons((prevDisabledButtons) => ({
          ...prevDisabledButtons,
          [item.id]: false,
        }));
      }, 1500);
    } catch (error) {
      // Handle error
      setDisabledButtons((prevDisabledButtons) => ({
        ...prevDisabledButtons,
        [item.id]: false,
      }));
    }
  };

  const incrementQuantityHandler = () => {
    if (variant?.quantity_on_hand > 0) {
      if (
        variant?.quantity <
        variant?.quantity_on_hand
      ) {
        dispatch(incrementQuantity({ variant: variant }));
      } else {
        message.warning({
          content: "No more quantity in stock",
          icon: <i className="fa fa-exclamation-circle" style={{ color: 'gold' }}/>
        });
      }
    }
  };

  const decrementQuantityHandler = () => {
    if (variant?.quantity > 1) {
      dispatch(decrementQuantity({ variant: variant }));
    } else {
      message.warning({
        content: "Minimum 1 quantity required",
        icon: <i className="fa fa-exclamation-circle" style={{ color: 'gold' }}/>
    });
    }
  };

  // Function to retrieve names of terpenes, effects, flavors based on their IDs
  const getNamesFromIds = (ids, data) => {
    return ids.map((id) => {
      const foundItem = data?.find((item) => item.id === id);
      return foundItem ? foundItem.name : ''; // Return name if found, empty string otherwise
    });
  };
  const getColorFromIds = (ids, data) => {
    return ids.map((id) => {
      const foundItem = data?.find((item) => item.id === id);
      return foundItem ? foundItem.color : ''; // Return color if found, empty string otherwise
    });
  };
  const getIconFromIds = (ids, data) => {
    return ids.map((id) => {
      const foundItem = data?.find((item) => item.id === id);
      return foundItem ? foundItem?.icon?.url : ''; // Return icon if found, empty string otherwise
    });
  }

  //For Related product
  // Variant chnage


  const productCard = document.querySelectorAll(
    ".product_cart:has(.addtocart_btn)"
  );
  const addCartBtn = document.querySelectorAll(".product_cart .addtocart_btn");
  const closeActive = document.querySelectorAll(
    ".product_cart:has(.addtocart_btn) .closeActive"
  );

  addCartBtn.forEach((e, ind) => {
    e.onclick = () => {
      if (activeProductCard !== productCard[ind]) {
        if (activeProductCard) {
          activeProductCard.classList.remove("active");
        }
        setActiveProductCard(productCard[ind]);
        productCard[ind].classList.add("active");
      }
    };
  });

  closeActive.forEach((e, ind) => {
    e.onclick = () => {
      productCard[ind].classList.remove("active");
      setActiveProductCard(null);
    };
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (activeProductCard && !activeProductCard.contains(event.target)) {
        activeProductCard.classList.remove("active");
        setActiveProductCard(null);
      }
    };

    const handleBodyClick = (event) => {
      handleClickOutside(event);
    };

    document.body.addEventListener("click", handleBodyClick);

    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };

  }, [activeProductCard]);

  // End for related product

  if (!selectedProduct) {
    return <>
      <Row justify='center'>
        <Col span={12}>
          <Skeleton.Image active style={{width:'500%'}}/>
        </Col>
        <Col span={12}>
          <Skeleton active />
          <Skeleton.Button active />
        </Col>
      </Row>
      {divider('Description')}
      <Skeleton active />
      {divider('Additional Cannabinoids')}
      <Skeleton active />
      {divider('Browse Similar Products')}
      <Skeleton active />
    </>;
  }


  const image = () => {
    let mapping = {
      "Flower": Constant.IMAGES.flower,
      "Edible Liquid": Constant.IMAGES.edible_liquid,
      "Edible Solid": Constant.IMAGES.edible_solid,
      "Paraphernalia": Constant.IMAGES.paraphernalia,
      "Preroll": Constant.IMAGES.pre_roll,
      "Topical": Constant.IMAGES.topical,
      "Sample": Constant.IMAGES.sample,
      "Concentrate": Constant.IMAGES.concentrate,
    }
    const category = categories.filter(c => c.id === product?.category_ids[0])[0]
    if (category) {
      return mapping[category.name]
    }else{ return Constant.FLOWER_IMAGE }
  }

  const render_terpenes = (item) => {
    if (item?.terpene_results && item?.terpene_results.length > 0) {
      return(
        item?.terpene_results.slice(0, 3).map((terpene) => (
          <div className="effect-name" key={terpene.name}>
            <img src="/images/Terpinolene.png" alt={terpene.name}></img>
            <p>{terpene.name}</p>
            {
              parseFloat(terpene.current) > 0 &&
              <p>{terpene.current} {terpene.unit_type}</p>
            }
          </div>
        ))
      )
    }
    else {
      return(
        item?.terpene_ids.slice(0, 3).map((terpeneId) => (
          <div className="effect-name" key={terpeneId}>
            {/* style={{ backgroundColor: getColorFromIds([terpeneId], menuFeedData.product_data.terpenes) }} */}
            <img src="/images/Terpinolene.png" alt="terpene"></img>
            <p>{getNamesFromIds([terpeneId], menuFeedData.product_data.terpenes)}</p>
          </div>
        ))
      )
    }
  }

  const displayPrice = () => {
    if(variant){
      let discountedTotal
      let selectedDiscount = discount
      let discount_type = discount?.coupon_type;
      let discount_amount = discount?.amount;
      let standard_price = variant.price_cents

      if(isMedMenu()){
        selectedDiscount = med_discount
        discount_type = med_discount?.coupon_type;
        discount_amount = med_discount?.amount;
        standard_price = variant.med_price_cents
      }

      if(discount_type === 'percent'){
        discountedTotal = (standard_price - (standard_price * discount_amount / 10000)) / 100
      } else if(discount_type === 'dollar') { discountedTotal = (standard_price - discount_amount) / 100 }
      else{ discountedTotal = discount_amount / 100}

      if(notNull(discountedTotal) && notNull(selectedDiscount)){
        return(
          <Space>
            <Text delete>
              ${notNull(standard_price) ? (standard_price / 100).toFixed(2) : '0.00'}
            </Text>
            <Title level={4} style={{marginBottom:0}}>
              ${discountedTotal.toFixed(2)}
            </Title>
          </Space>
        )
      }else{
        return <Text>
          ${notNull(standard_price) ? (standard_price / 100).toFixed(2) : '0.00'}
        </Text>
      }
    }
  }

  return (
    <section className="breadcrumb mt-4">
      <div className="container product-info">
        {selectedProduct?.map((item) => {

          const discountIdForMedPrice =
            item?.variants[0]?.discount_id_for_med_price;
          const discountIdForPrice = item?.variants[0]?.discount_id_for_price;

          const discountForMedPrice =
            menuFeedData?.product_data?.discounts?.find(
              (d) => d.id === discountIdForMedPrice
            );
          const discountForPrice = menuFeedData?.product_data?.discounts?.find(
            (d) => d.id === discountIdForPrice
          );

          const discountNameForMedPrice = discountForMedPrice?.item_display_name;
          const discountNameForPrice = discountForPrice?.item_display_name;

          const chosenDiscountName = isMedMenu() ? med_discount?.item_display_name : discount?.item_display_name;

          const sdpCategoryDescription = sdpData?.find(
            (category) => category?.sdp_category === item?.sdp_category
          )?.description;
          const sdpCategoryIcon = sdpData?.find(
            (category) => category?.sdp_category === item?.sdp_category
          )?.icon_url;

          const sdpnearestCategories = sdpData?.find(
            (category) => category?.sdp_category === item?.sdp_category
          )?.nearest_sdp_category;
          const sdpCategoryName = sdpData?.find(
            (category) => category?.sdp_category === item?.sdp_category
          )?.name;
          const sdpCategoryColor = sdpData?.find(
            (category) => category?.sdp_category === item?.sdp_category
          )?.color;

          const sdpclosetCategoryColor = sdpData?.find(
            (category) => category?.sdp_category === sdpnearestCategories
          )?.color;

          const style1 = {
            backgroundColor: sdpCategoryColor,

          };
          const style = {
            backgroundColor: sdpclosetCategoryColor,

          };
          const sdpclosestCategoryName = sdpData?.find(
            (category) => category?.sdp_category === sdpnearestCategories
          )?.name;

          return (
            <React.Fragment key={item?.id}>
              <nav aria-label="breadcrumb" key={item?.id}>
                <div className="back-btn detail-heading">
                  <Link onClick={() => navigate(-1)}>
                    <i className="fa fa-arrow-left"/> Back
                  </Link>
                </div>
              </nav>
              <Row>
                <Col
                  span={24}
                  style={{paddingRight: 20}}
                  sm={{span: 11,offset: 1}}
                  md={{span: 10,offset: 2}}
                  lg={{span: 9,offset: 3}}
                  xl={{span: 7,offset: 5}}
                  xxl={{span: 6, offset: 6}}
                >
                <Badge.Ribbon text={chosenDiscountName} color={settings?.secondary_color}>
                  <Flex justify='center' align='center'>
                    <Image
                      src={displayImage}
                      width={isMobile ? '70%' : ''}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = image()
                      }}
                      alt={item?.variants[0].name}
                    />
                  </Flex>
                </Badge.Ribbon>
                  {variant?.images?.length > 1 &&
                    <List
                      grid={{ gutter: 4, column: 7}}
                      dataSource={variant?.images}
                      renderItem={(img) => (
                        <List.Item style={{width: 50}}>
                          <img
                            style={{border: displayImage === img?.lg ? '2px solid #1890ff' : '1px solid grey', borderRadius: '5px', padding: '5px', maxWidth: 35}}
                            src={img?.lg}
                            alt={variant.name}
                            className="product-image"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = image()
                            }}
                            onClick={() => setDisplayImage(img?.lg)}
                          />
                        </List.Item>
                      )}
                    />
                  }
                </Col>
                <Col
                  span={24}
                  style={{paddingLeft: isDesktop && 20}}
                  sm={{span: 12}}
                >
                  <Title level={isMobile ? 4 : 2}>{item?.name}</Title>
                  <Text strong >{item?.brand}</Text>
                  <Row size='small'>
                    <Col span={12}>
                    {
                      notNull(settings) && item?.is_marijuana &&
                      <span>
                        {
                          item?.[primaryThc] !== null &&
                          <TestResult item={item} type={primaryThc}/>
                        }
                        {
                          item?.[primaryCbd] !== null &&
                          <TestResult item={item} type={primaryCbd}/>
                        }
                      </span>
                    }

                      {((item.flower_type && item.flower_type.length !== 0) || sdpCategoryIcon) && (
                        <div className="d-flex">
                            {item.flower_type && item.flower_type.length !== 0 && (
                              <LineageTag lineage={item.flower_type}/>
                            )}
                            {sdpCategoryIcon && (
                              <img
                              src={sdpCategoryIcon}
                              alt={sdpCategoryName}
                              className={"sdp_category_icon"}
                              />
                            )}
                            {
                              variant?.doh_compliant &&
                              <img className="sdp_category_icon" src={window.location.origin + '/images/doh_image.png'} alt='doh compliant'/>
                            }
                          </div>
                        )
                      }
                    </Col>
                    <Col span={12}>
                    </Col>
                    <Row>
                      {displayPrice()}
                      {/* // {
                      //   (isMedMenu() && variant?.discounted_med_price_cents) ||
                      //   variant?.discounted_price_cents ? (
                      //     <Space>
                      //       <Title delete type='secondary' level={isMobile ? 4 : 5} style={{marginBottom: 0}}>
                      //         ${(isMedMenu() ?
                      //             variant?.med_price_cents / 100 :
                      //             variant?.price_cents / 100
                      //           ).toFixed(2)}
                      //       </Title>
                      //       <Title level={4} type='success' style={{marginBottom:0}}>
                      //         $
                      //         {(isMedMenu() ?
                      //           variant?.discounted_med_price_cents / 100 :
                      //           variant?.discounted_price_cents / 100
                      //         ).toFixed(2)}
                      //       </Title>
                      //     </Space>
                      //   ) : (
                      //     <Title level={4} style={{marginBottom:0}}>
                      //       $
                      //       {(isMedMenu() ?
                      //         variant?.med_price_cents / 100 :
                      //         variant?.price_cents / 100
                      //       ).toFixed(2)}
                      //     </Title>
                      //   )
                      // } */}
                    </Row>
                  </Row>
                  <Row style={{marginTop: 15}}>
                    <Col span={isMobile ? 12 : 8}>
                    <Space direction='vertical'>
                      <Text strong>Weight:</Text>
                      {item?.variants?.length > 1 ? (
                        <Select
                            style={{width: '200'}}
                            suffixIcon={<i className="fa fa-chevron-down" style={{color: 'grey'}}/>}
                            defaultValue={notNull(variant) ? variant?.id : product?.variants?.[0]?.id}
                            autoFocus={false}
                            onChange={changeVariant}
                            options={item?.variants?.map((vt) => {
                              return {label: `${vt?.unit} ${vt?.unit_type}`, value: vt?.id}
                            })
                          }
                        />
                        ) : (
                          <WeightRange product={item} />
                        )
                      }
                    </Space>
                    </Col>
                    <Col span={isMobile ? 12 : 8}>
                      <Space direction='vertical'>
                        <Text strong>Quantity:</Text>
                        <Flex justify='space-between' align='center' style={{width: '100%'}}>
                          <Space
                            size={13}
                          >
                          <Button
                            shape='circle'
                            // size='small'
                            onClick={decrementQuantityHandler}
                            icon={<i className="fa fa-minus"/>}
                            type='text'
                            style={{
                              boxShadow: Constant.BOX_SHADOW
                            }}
                            disabled={variant?.quantity <= 1}
                          />
                          <Text>{variant?.quantity}</Text>
                          <Button
                            shape='circle'
                            // size='small'
                            onClick={incrementQuantityHandler}
                            icon={<i className="fa fa-plus"/>}
                            type='text'
                            style={{
                              boxShadow: Constant.BOX_SHADOW
                            }}
                          />
                          </Space>
                        </Flex>
                      </Space>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={isMobile ? 24 : 16}>
                      <Button
                        size="large"
                        type="primary"
                        loading={waiting}
                        block
                        onClick={() => addToCartItem(variant)}
                        style={{
                          padding: '8px 35px 8px 35px',
                          marginTop: 20,
                          width: '75%',
                          minWidth: 300,
                          maxWidth: 450,
                          box_shadow: Constant.BOX_SHADOW
                        }}
                      >
                        <Space>
                          {Constant.ADD_TO_CART}
                          <i className="fa fa-cart-shopping prd_dtl"/>
                        </Space>
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>


              {item?.description !== "" && (
                <div >
                  {divider(title('Description'))}
                    <div
                      style={{textAlign: "justify"}}
                      dangerouslySetInnerHTML={{ __html: item?.description }}
                    />
                </div>
              )}
              {divider(title('Additional Cannabinoids'))}
              {/* Addition detail section */}
              <div className="additional-section mt-4">
                <div className="price-discount-section d-flex mt-3">
                  {
                    item?.is_marijuana && item?.test_results && ['thc',
                      'thca',
                      'thcva',
                      'total_thc',
                      'cbd',
                      'cba',
                      'cbdv',
                      'cbc',
                      'cbca',
                      'cbcv',
                      'cbn',
                      'cbg',
                      'cbgv',
                      'total_cbd',
                    ]?.filter(tr =>  ![primaryThc, primaryCbd].includes(tr) &&
                    settings?.[`display_${tr}`] &&
                    notNull(item?.test_results?.[tr]) &&
                    item?.test_results?.[tr]?.current > 0
                    )
                    ?.map(tr => {
                      return  (
                        <TestResult key={tr} type={tr} item={item} />
                      )
                    }
                    )
                  }
                </div>
                <div className="extra-info-section mt-4 mb-4">
                  <div className="row">
                    {item?.effect_ids && item.effect_ids.length > 0 && (
                      <div className="col-md-4 col-sm-4">
                        <h5>Effects</h5>
                        <div className="effect-section">
                          {/* Map through effect IDs and display their names */}
                          {item?.effect_ids.slice(0,3).map((effectId) => (
                            <div className="effect-name" key={effectId}>
                              <img src={getIconFromIds([effectId], menuFeedData.product_data.effects)} alt="effect"></img>
                              <p>{getNamesFromIds([effectId], menuFeedData.product_data.effects)}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {item?.flavor_ids && item.flavor_ids.length > 0 && (
                      <div className="col-md-4 col-sm-4">
                        <h5>Flavor</h5>
                        <div className="effect-section">
                          {/* Map through flavor IDs and display their names */}
                          {item?.flavor_ids.slice(0,3).map((flavorId) => (
                            <div className="effect-name" key={flavorId}>
                              <img src={getIconFromIds([flavorId], menuFeedData.product_data.flavors)} alt="effect"></img>
                              <p>{getNamesFromIds([flavorId], menuFeedData.product_data.flavors)}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {!item?.sdp_category && item?.terpene_ids && item.terpene_ids.length > 0 && (
                      <div className="col-md-4 col-sm-4">
                        <h5>Terpenes</h5>
                        <div className="effect-section">
                          {/* Map through terpene IDs and display their names */}
                          {render_terpenes(item)}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                { item?.sdp_category &&
                  <div className="strain_data mb-4">
                    <div className="strain_data_heading">
                      <span className="terpenes_text mr-2">Terpenes </span>
                      {sdp_logo && (
                        <img className="sdp_logo_img" src={sdp_logo} alt="sdpImage" />
                      )}
                    </div>
                    <div className="more-product-detail">
                      <div className="product-graph mt-4 ">
                        <div className="product-catogory mb-4">

                          {sdpCategoryName && (
                            <><span className="detail-heading">Category for this Product:{" "}</span><span>
                              <span className="sdpbadge" style={style1}>
                                {sdpCategoryName}
                              </span>
                            </span></>
                          )}
                        </div>
                        {item?.sdp_compass && (
                          <img
                          className="mx-auto d-block sdp_compass"
                          src={item?.sdp_compass}
                          style={{"padding-left": "10px", "padding-right": "10px"}}
                          alt="product-graph"
                          ></img>
                        )}
                      </div>

                      <div className="details mt-4">
                        <h6 className="detail-heading">
                          What's your favorite color?
                        </h6>
                        <p>
                          The Strain Data compass is part of a larger research
                          effort called the Strain Data Project (SDP), from which
                          this information was derived.
                        </p>
                        <p>
                          SDP utilized data from thousands of tested cannabis
                          samples. The data was analyzed and aggregated in order to
                          discover similarities and differences between cannabis
                          products.
                        </p>
                        <p>
                          Each colored coded category is defined primarily by the
                          most dominant terpene or combination of terpenes. This
                          creates a signature shape on a radar chart.
                        </p>
                        <a href="https://straindataproject.org/" rel="noreferrer" target="_blank">Learn more about the Strain Data Project</a>
                      </div>

                      <div className="most-detail-section">

                        {sdpCategoryDescription && (
                          <p className="mt-4">
                            <span className="detail-heading">Category Description:{" "}</span>
                            {sdpCategoryDescription}
                          </p>
                        )}

                        {sdpclosestCategoryName && (
                          <div className="d-flex closet_catagories">
                            <><span className="detail-heading">Closest Categories: {" "}</span><span>
                              <span className="sdpbadge" style={style}>
                                {sdpclosestCategoryName}
                              </span>
                            </span></>
                          </div>
                        )}
                        {item?.terpene_ids && item.terpene_ids.length > 0 && (
                          <><div className="mt-4 detail-heading"> Top 3 Most Abundant Terpenes</div>
                            <div className="abundant-section">
                              <div className="terpen-list">
                                {item?.terpene_results.slice(0, 4).map((terpene) => (
                                  <p>{terpene.name}</p>
                                ))}
                              </div>
                              <div className="terpen-percent">
                                {item?.terpene_results.slice(0, 4).map((terpene) => (
                                    <p>{terpene.current} {terpene.unit_type}</p>
                                ))}
                              </div>
                              <div className="">
                                <div className="powered by">Powered by</div>
                                <div className="confidence-logo">
                                  {iron_stem_logo && (
                                    <img src={iron_stem_logo} alt="certified-logo"/>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                      </div>
                    </div>
                  </div>
                }
              </div>
            </React.Fragment>
          );
        })}

        {/* Related Products Section */}
        {
          relatedProducts?.length > 0 &&
          <>
          {divider(title('Browse Similar Products'))}
          <Swiper
            slidesPerView={5}
            spaceBetween={0}
            onSwiper={setSwiperRef}
            a11y={{
              enabled: true,
              prevSlideMessage: 'Previous slide',
              nextSlideMessage: 'Next slide',
            }}
            breakpoints={{
              350: {slidesPerView: 1.3},
              490: {slidesPerView: 1.5},
              575: {slidesPerView: 1.7},
              636: {slidesPerView: 2.1},
              715: {slidesPerView: 2.5},
              900: {slidesPerView: 3.1},
              1030: {slidesPerView: 3.5},
              1170: {slidesPerView: 4.1},
              1360: {slidesPerView: 4.5},
              1460: {slidesPerView: 5.2},
            }}
            onReachEnd={() => setShownProducts(shownProducts + 8)}
            freeMode={true}
            navigation={true}
            virtual={true}
            // pagination={{
            //   clickable: true,
            // }}
            modules={[FreeMode, Pagination, Navigation, Virtual]}
          >
            {relatedProducts?.length > 0
            ? (
              relatedProducts.slice(0,shownProducts)?.map((item, key) => {
                return (
                  // <ProductCard product={item} key={item?.id} />
                  <SwiperSlide key={item?.id}>
                    <DynamicProductCard product={item} key={item?.id} />
                  </SwiperSlide>
                );
              })
              )
            : (
              <div className="col-md-12 no_product">
                {" "}
                {Constant.NO_PRODUCTS_AVAILABLE}
              </div>
            )}
          </Swiper>
          </>
        }
      </div>
    </section>
  );
};

export default ProductDetail;
